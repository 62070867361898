import React from 'react';
import { Link } from 'react-router-dom';
// import Slider from 'react-slick';
import { newMenu1 } from '../../const/menu/newMenu1';
import { newMenu2 } from '../../const/menu/newMenu2';

// eslint-disable-next-line
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import 'swiper/css';

import { newMenuPop } from '../../const/menu/newMenuPop';

export function Newmenu() {
  return (
    <div className="newmenu">
      <div className="brand-title" style={{ height: '50px' }}>
        <div className="ment"></div>
        <div className="sub-tabmenu" style={{ marginTop: '0px' }}>
          <div className="inner">
            <ul>
              <li className="on">
                <Link to="/newmenu">신메뉴</Link>
              </li>
              <li>
                <Link to="/allmenu">전체메뉴</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="inner2">
        <div className="section4">
          <span>
            홈 {'>'} 메뉴소개 {'>'} 신메뉴
          </span>
        </div>
        <div className="section1">
          <strong>신메뉴</strong>
          <p>이공커피만의 새로운 시즌메뉴</p>
        </div>
        <div className="section2" style={{ border: 'none' }}>
          <Swiper
            loop={true}
            slidesPerView={5}
            speed={3000}
            modules={[Autoplay]}
            autoplay={{
              delay: 0,
              disableOnInteraction: false,
            }}
            breakpoints={{
              300: {
                slidesPerView: 2,
              },
              768: {
                slidesPerView: 3,
              },
            }}
          >
            {newMenuPop.map((item, index) => (
              <SwiperSlide key={index}>
                <img src={item.imgUrl} alt={item.name} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        <div className="section3">
          <div className="sub-title">
            <strong>신메뉴 소개</strong>
          </div>
          <Swiper
            loop={true}
            slidesPerView={7}
            speed={3000}
            freeMode={false}
            modules={[Autoplay]}
            autoplay={{
              delay: 0,
              disableOnInteraction: false,
            }}
            breakpoints={{
              300: {
                slidesPerView: 3,
              },
              768: {
                slidesPerView: 4,
              },
            }}
          >
            {newMenu1.map((item, index) => (
              <SwiperSlide key={index}>
                <img src={item.imgUrl} alt={item.name} />
                <span>{item.name}</span>
              </SwiperSlide>
            ))}
          </Swiper>
          <Swiper
            loop={true}
            slidesPerView={7}
            speed={3000}
            modules={[Autoplay]}
            autoplay={{
              delay: 0,
              disableOnInteraction: false,
            }}
            breakpoints={{
              300: {
                slidesPerView: 3,
              },
              768: {
                slidesPerView: 4,
              },
            }}
          >
            {newMenu2.map((item, index) => (
              <SwiperSlide key={index}>
                <img src={item.imgUrl} alt={item.name} />
                <span>{item.name}</span>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
}
