import React from 'react';
import { Link } from 'react-router-dom';

export function Bi() {
  return (
    <div className="bi">
      <div className="brand-title">
        <img src="/img/tab-yigong.png" alt="" />
        <div className="ment">
          <strong>이공커피</strong>
          {/* <p>합리적인 가격과 높은 품질의 커피</p> */}
        </div>
        <div className="sub-tabmenu">
          <div className="inner">
            <ul>
              <li>
                <Link to="/main">이로운공간</Link>
              </li>
              <li className="on">
                <Link to="/bi">BI</Link>
              </li>
              <li>
                <Link to="/intro">오시는 길</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="inner">
        <div className="section1">
          <strong>Brand Identity</strong>
        </div>
        <div className="section2">
          <div>
            <strong>
              이 : 이로울 리(이)<strong>利</strong> <br />공 : 빌 공{' '}
              <strong>空</strong>
            </strong>
          </div>

          <br />
          <span>
            <p>이로운 공간</p>
            <p>
              이공커피 바쁜 일상 속에 지친 우리들에게 잠시나마 달콤한 휴식과
              건강한 에너지를 주는 공간을 표현하기 위해
            </p>
            <p>이름 그대로의 한자를 편안하고 완만한 곡선으로 표현하였습니다.</p>
          </span>
        </div>
        <div className="section3">
          <div>
            <img src="img/logo1.png" alt="" />
          </div>
          <div>
            <img src="img/logo2.png" alt="" />
          </div>
        </div>
        <div className="section4">
          <div className="content">
            <strong>이공로고</strong>
            <div>
              <img src="/img/logo5.png" alt="" />
            </div>
          </div>
          <div className="content">
            <strong>이공글씨</strong>
            <div>
              <img src="/img/logo6.png" alt="" />
            </div>
          </div>
          <div className="content">
            <strong>이공컬러</strong>
            <div className="yigong-color">
              <div>
                <button style={{ backgroundColor: '#ede0d1' }} />
                <p style={{ color: '#ede0d1' }}>#ede0d1</p>
              </div>
              <div>
                <button style={{ backgroundColor: '#a97550' }} />
                <p style={{ color: '#a97550' }}>#a97550</p>
              </div>
              <div>
                <button style={{ backgroundColor: '#533c34' }} />
                <p style={{ color: '#533c34' }}>#533c34</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
