import { Link, useParams } from 'react-router-dom';
import { notice } from '../../const/community/notice';

export const NoticeCont = () => {
  let { id } = useParams();
  const post = notice.find((item) => item.id === parseInt(id));
  const postId = parseInt(id);
  const postIndex = notice.findIndex((item) => item.id === postId);

  const prevPost = postIndex > 0 ? notice[postIndex - 1] : null;
  const nextPost = postIndex < notice.length - 1 ? notice[postIndex + 1] : null;

  return (
    <div className="community">
      <div className="noticeCont">
        <div className="brand-title">
          <img src="/img/tab-commu2.png" alt="" />
          <div className="ment">
            <strong>커뮤니티</strong>
            {/* <p>무엇을 도와드릴까요</p> */}
          </div>
          <div className="sub-tabmenu">
            <div className="inner">
              <ul>
                <li className="on">
                  <Link to="/notice">공지사항</Link>
                </li>
                <li>
                  <Link to="/news">뉴스</Link>
                </li>
                {/* <li>
              <Link to="/event">이벤트</Link>
            </li> */}
              </ul>
            </div>
          </div>
        </div>
        <div className="inner">
          <div className="section1">
            <strong>공지사항</strong>
          </div>
          <div className="section2">
            <h1>{post.title}</h1>
            <div style={{ textAlign: 'right' }}>
              <span className="date" style={{ paddingRight: '30px' }}>
                {post.date}
              </span>
            </div>
          </div>
          <div className="section3">
            <img src={post.imgUrl} alt="" />
          </div>
          <div className="navigation">
            {prevPost && (
              <Link to={`/notice/${prevPost.id}`}>
                <img src="/img/arrow_up.png" alt="" />
                <span>{prevPost.title}</span>
              </Link>
            )}
            {nextPost && (
              <Link to={`/notice/${nextPost.id}`}>
                <img src="/img/arrow_down.png" alt="" />
                <span>{nextPost.title}</span>
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
