/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useState } from 'react';
import { Stores } from '../const/stores';

export function StoreFindModal({ isOpen, onClose }) {
  const handleClickOutside = (e) => {
    if (e.target.className === 'modal') {
      onClose();
    }
  };

  if (!isOpen) return null;

  const [selectedRegion, setSelectedRegion] = useState('');
  const [filteredStores, setFilteredStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState({
    address: '매장 위치',
    mapUrl: 'https://kko.to/jmDsBMPGfa',
  });

  const handleRegionChange = (event) => {
    setSelectedRegion(event.target.value);
  };

  const handleStoreChange = (event) => {
    const selectedStoreTitle = event.target.value;

    const selectedStore = Stores().find(
      (store) => store.title === selectedStoreTitle
    );

    if (selectedStore) {
      setSelectedStore(selectedStore); // 이제 selectedStore 상태는 선택된 매장의 주소를 가집니다.
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // console.log(selectedStore.mapUrl);
    window.open(selectedStore.mapUrl, '_blank');
  };

  useEffect(() => {
    setFilteredStores(Stores());
  }, []);

  useEffect(() => {
    const A = Stores().filter((store) =>
      store.address.includes(selectedRegion)
    );
    setFilteredStores(A);
  }, [selectedRegion]);

  return (
    <div className="modal" onClick={handleClickOutside}>
      <div className="modal-content-findStore">
        <label>매장찾기</label>
        <div className="frm" id="frm">
          <div className="f_wrap animation">
            <ul>
              <li>
                <div className="d_wr">
                  <select
                    className="counselCompany"
                    name="location"
                    onChange={handleRegionChange}
                  >
                    <option value="defalut">지역을 선택해주세요</option>
                    <option value="">전체</option>
                    <option>서울</option>
                    <option>부산</option>
                  </select>
                </div>
              </li>
              <li>
                <div className="d_wr">
                  <select
                    className="counselCompany"
                    name="location2"
                    onChange={handleStoreChange}
                  >
                    <option>매장을 선택해주세요</option>

                    {filteredStores.map((item, index) => (
                      <option key={index}>{item.title}</option>
                    ))}
                  </select>
                </div>
              </li>
              <li>
                <div className="store-list">
                  <p>{selectedStore.address}</p>
                </div>
              </li>
            </ul>

            <div className="submit">
              <button type="submit" onClick={handleSubmit}>
                길안내
              </button>
              <button onClick={onClose} className="close-button">
                닫기
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
